import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const AutomaticEvaluationTriggerVariablesColumns = [
  {
    field: 'variableLabel',
    headerName: 'Variable',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={<Typography>Por código: {row.variable}</Typography>}>
        <Typography>{row.variableLabel}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'operatorDisplay',
    headerName: 'Operador',
    align: 'center',
    headerAlign: 'center',
    flex: 0.5,
  },
  {
    field: 'comparedToVariableLabel',
    headerName: 'Variable que Compara',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={<Typography>Por código: {row.comparedToVariable}</Typography>}>
        <Typography>{row.comparedToVariableLabel}</Typography>
      </Tooltip>
    ),
  },
];

export default AutomaticEvaluationTriggerVariablesColumns;
