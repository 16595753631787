import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AutomaticEvaluationMaintainerEditActivateComponent from '../components/automatic-decision-manager/components/AutomaticEvaluationEditActivateComponent';
import AutomaticDecisionTriggerVariablesDialog from '../components/automatic-decision-manager/dialogs/AutomaticEvaluationTriggerVariablesDialog';
import AutomaticEvaluationMaintainerVariablesDialog from '../components/automatic-decision-manager/dialogs/AutomaticEvaluationMaintainerVariablesDialog';

const AutomaticDecisionManagerColumns = [
  {
    field: 'order',
    headerName: 'Orden',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => <Stack display="flex" alignItems="center"><Typography variant="small_1">{row.order}</Typography></Stack>,
  },
  {
    field: 'priority',
    headerName: 'Prioridad',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => <Stack display="flex" alignItems="center"><Typography variant="small_1">{row.priority}</Typography></Stack>,
  },
  {
    field: 'description',
    headerName: 'Descripción',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => <Stack display="flex" alignItems="center"><Typography variant="small_1">{row.description}</Typography></Stack>,
  },
  {
    field: 'active',
    headerName: 'Activo',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => <AutomaticEvaluationMaintainerEditActivateComponent row={row} />,
  },
  {
    field: 'triggerVariables',
    headerName: 'Reglas de Motor de Decisión',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <AutomaticDecisionTriggerVariablesDialog
        maintainerId={row.id}
        maintainerDescription={row.description}
      />
    ),
  },
  {
    field: 'decisionVariables',
    headerName: 'Variables de Decisión del Motor Automático',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => <AutomaticEvaluationMaintainerVariablesDialog maintainerId={row.id} />,
  },
];

export default AutomaticDecisionManagerColumns;
