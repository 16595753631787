import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const AutomaticEvaluationMaintainerVariablesColumns = [
  {
    field: 'order',
    headerName: 'Orden',
    align: 'center',
    headerAlign: 'center',
    flex: 0.3,
  },
  {
    field: 'variable',
    headerName: 'Variable',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={<Typography>Por código: {row.variable}</Typography>}>
        <Typography>{row.variableLabel}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'operatorDisplay',
    headerName: 'Operador',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'comparedToVariable',
    headerName: 'Variable que Compara',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={<Typography>Por código: {row.comparedToVariable}</Typography>}>
        <Typography>{row.comparedToVariableLabel}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'resolutionDisplay',
    headerName: 'Resolución',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'resolutionComment',
    headerName: 'Comentario de Resolución',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
];

export default AutomaticEvaluationMaintainerVariablesColumns;
